import React from 'react'

function HyperLinkCard({ item }) {
    return (
        <a href={item.url} key={item.id} target='_blank' className='flex w-full justify-between items-center bg-neutral-100 rounded-lg px-4 py-3 shadow hover:scale-105 transition-all duration-300 '>
            <span className='font-[500] text-[14px]'>{item.anchor}</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>


        </a>
    )
}

export default HyperLinkCard