import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BlogCard from '../components/BlogCard';
import HyperLinkCard from '../components/HyperLinkCard';
import LoadingPage from '../components/LoadingPage';
import { Helmet } from 'react-helmet';

function Home() {
    const [Categories, setCategories] = useState([])
    const [Blogs, setBlogs] = useState([])
    const [HyperLinks, setHyperLinks] = useState([])
    const [website, setwebsite] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [CategorySelected, setCategorySelected] = useState([])

    const [Loading, setLoading] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await getCategories();
                await getBlogs();
                await getHyperLink();
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [CategorySelected]);


    const getCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/categories/search_temp/${process.env.REACT_APP_BACKEND_NAME}`);
            const responseData = response.data;

            if (responseData) {

                setCategories(responseData.data);
            }
        } catch (error) {
            console.error(error.response?.data?.message || 'An error occurred while fetching the categories.');
        }
    };

    const getHyperLink = async (page = 1) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hyperLinks/searchHyperLinks/${process.env.REACT_APP_BACKEND_NAME}?page=${page}`, { category: CategorySelected });
            const responseData = response.data;

            if (responseData) {

                setHyperLinks(responseData.data);
            }
        } catch (error) {
            console.error(error.response?.data?.message || 'An error occurred while fetching the categories.');
        }
    };

    const getBlogs = async (page = 1) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/blogs/searchBlogs/${process.env.REACT_APP_BACKEND_NAME}?page=${page}`, { category: CategorySelected });
            const responseData = response.data;

            if (responseData) {
                setBlogs(responseData.data);
                setCurrentPage(responseData.current_page);
                setTotalPages(responseData.last_page);
                setwebsite(responseData.website);
            }
        } catch (error) {
            console.error(error.response?.data?.message || 'An error occurred while fetching the blogs.');
        }
    };



    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className='w-full min-h-screen h-full flex-col flex justify-start items-center px-3 sm:px-14 md:px-28 lg:px-44 xl:px-64 xl:py-14 py-5'>

            <Helmet>
                <title>{website?.meta_title}</title>
                <meta name="description" content={website?.meta_description} />
            </Helmet>

            {Loading &&
                <LoadingPage />
            }

            <div className='flex justify-start items-center w-full'>
                <h1 className='text-center font-[800] text-neutral-700 xl:text-[50px] text-[30px] xl:leading-[50px] leading-[30px]'>
                    {process.env.REACT_APP_SITE_NAME}
                </h1>
                {/* <button className='w-fit px-7  py-2 flex justify-center items-center border-[1.5px] border-primary rounded-full text-[16px] font-[600] text-primary  hover:border-neutral-200 hover:text-neutral-200 hover:bg-primary transition-all duration-300 '>
                    Link Here
                </button> */}
            </div>

            <div className='flex gap-4 xl:py-10 py-5 w-full flex-wrap'>
                <a onClick={() => setCategorySelected(null)} title='Filter' className='flex justify-center shadow hover:scale-105 transition-all duration-300 cursor-pointer items-center px-5 py-2 font-[600] bg-gray-100 rounded-md'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </a>
                {
                    Categories.length > 0 &&
                    Categories.map(item => {
                        return (
                            <a key={item.id} onClick={() => setCategorySelected(item.id)} title='Filter' className={`flex justify-center ${CategorySelected === item.id ? 'border-primary border-[1.5px]' : ''} shadow hover:scale-105 transition-all duration-300 cursor-pointer items-center px-5 py-2 font-[600] bg-gray-100 rounded-md`}>
                                <p>
                                    {item.name}
                                </p>
                            </a>
                        )
                    })
                }
            </div>


            <div className='flex justify-center xl:flex-row flex-col xl:items-start  w-full gap-10'>
                <ul className="grid grid-cols-1 xl:grid-cols-3 gap-y-10 gap-x-6 items-start xl:w-3/4 w-full">
                    {
                        Blogs.length > 0 &&
                        Blogs.map(item => {
                            return (
                                <BlogCard item={item} />
                            )
                        })
                    }
                </ul>


                <div className='flex h-full items-start flex-col gap-2  xl:w-1/4 w-full '>
                    {
                        HyperLinks.length > 0 &&
                        HyperLinks.map(item => {
                            return (
                                <HyperLinkCard item={item} />
                            )
                        })
                    }
                </div>
            </div>








            {Blogs.length > 10 &&
                <div className="mt-6 flex w-full items-center justify-start gap-4">

                    <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                        <button
                            onClick={handlePrevious}
                            disabled={currentPage === 1}
                            className="flex items-center justify-center w-fit px-2 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 "
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>
                            <span>Previous</span>
                        </button>
                        <div className="text-sm text-neutral-500 dark:text-neutral-400">
                            Page <span className="font-medium text-neutral-800">{currentPage} of {totalPages}</span>
                        </div>
                        <button
                            onClick={handleNext}
                            disabled={currentPage === totalPages}
                            className="flex items-center justify-center w-fit px-2 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 "
                        >
                            <span>Next</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Home