import React from 'react'
import { Link } from 'react-router-dom'

function BlogCard({ item }) {
    return (
        <li key={item.id}  className="relative flex flex-col sm:flex-row xl:flex-col items-start">
            <div className="order-1 sm:ml-6 xl:ml-0">
                <h3 className="mb-1 text-slate-900 font-semibold line-clamp">
                    <span className="mb-1 block text-sm leading-6 text-primary ">{item.category.name}</span>
                    {item.title}
                </h3>
                <div className="prose prose-slate prose-sm line-clamp text-slate-600">
                    <p> {item.description}</p>
                </div>
                <Link to={`/Post/${item.slug}`} className="group inline-flex items-center h-9 rounded-md text-sm cursor-pointer font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-slate-100 text-slate-700 hover:bg-slate-200 hover:text-slate-900 focus:ring-slate-500 mt-3" href>Learn more
                    <svg className="overflow-visible ml-3 text-slate-300 group-hover:text-slate-400" width={3} height={6} viewBox="0 0 3 6" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                        <path d="M0 0L3 3L0 6" />
                    </svg>
                </Link>
            </div>
            <img src={item.image} alt className="mb-2  h-[200px] bg-contain shadow-md rounded-lg bg-slate-50 w-full sm:w-[17rem] sm:mb-0 xl:mb-3 xl:w-full "  />
        </li>
        )
}

export default BlogCard