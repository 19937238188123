import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Post from './pages/Post';
import NotFound from './pages/NotFound';
import Footer from './components/Footer';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Post/:slug" element={<Post />} />
        <Route path="/*" element={<NotFound />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
