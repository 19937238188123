import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
import LoadingPage from '../components/LoadingPage';
import { Helmet } from 'react-helmet';

function Post() {

    const { slug } = useParams()
    const [postData, setPostData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        const fetchPostData = async () => {
            if (slug) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blogs/FindBlog/${process.env.REACT_APP_BACKEND_NAME}/${slug}`);
                    const responseData = response.data;

                    if (responseData) {
                        setPostData(responseData.data);
                    }
                } catch (error) {
                    console.error('Error fetching post data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchPostData();
    }, [slug]);

const formatDate = (inputDate) => {
    return new Date(inputDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long', // 'short' for abbreviated month
      day: 'numeric'
    });
  };

    return (
        <div className='w-full min-h-screen h-full flex-col flex justify-start items-center px-3 sm:px-14 md:px-28 lg:px-44 xl:px-64 xl:py-14 py-5'>
             <Helmet>
                <title>{postData?.meta_title}</title>
                <meta name="description" content={postData?.meta_description} />
            </Helmet>
            {loading &&
                <LoadingPage />
            }
            <div className='flex justify-between items-center w-full'>
                <Link  to='/' className='text-center font-[800] text-neutral-700 xl:text-[50px] text-[30px] xl:leading-[50px] leading-[30px]'>
                    {process.env.REACT_APP_SITE_NAME}
                </Link>
                <button className='w-fit px-7  py-2 flex justify-center items-center border-[1.5px] border-primary rounded-full text-[16px] font-[600] text-primary  hover:border-neutral-200 hover:text-neutral-200 hover:bg-primary transition-all duration-300 '>
                    Link Here
                </button>
            </div>


            <div className='w-full grid   xl:py-20 py-8'>
                <div className='flex justify-between items-center '>
                    <div className='flex text-white justify-center items-center px-5 py-1 bg-primary rounded-md w-fit'>
                        {postData?.category?.name}
                    </div>

                    <div className='flex text-neutral-600  justify-center items-center'>
                        {formatDate(postData?.schedule_at)}
                    </div>
                </div>

                <h1 className='text-start mt-3 font-[600] text-neutral-700 xl:text-[30px] text-[20px] xl:leading-[50px] leading-[30px]'>
                    {postData?.title}
                </h1>
                <p className='text-start font-[400] text-neutral-700 xl:text-[20px] text-[15px] xl:leading-[50px] leading-[30px]'>
                    {postData?.description}
                </p>
                <div className='flex justify-center mt-5 items-center w-full'>
                    <img
                        src={postData?.image}
                        alt='Post Image'
                        className='h-96 rounded-xl w-full object-cover'
                    />
                </div>

                <div className='flex justify-center items-start mt-5 flex-col' dangerouslySetInnerHTML={{ __html: postData?.content }}>
                </div>
            </div>
        </div>
    )
}

export default Post