import React from 'react';

function Footer() {
    const currentYear = new Date().getFullYear(); // Get the current year

    return (
        <div className='flex  justify-center items-center w-full py-6 bg-gray-200 border-t border-gray-200'>
            <span className="block text-sm text-gray-700 sm:text-center ">
                © {currentYear} <a className="hover:underline">{process.env.REACT_APP_SITE_NAME}</a>. All Rights Reserved.
            </span>
        </div>
    );
}

export default Footer;
