import React from 'react'

function LoadingPage() {
    return (
        <div className="flex space-x-2 justify-center items-center bg-white/30 absolute top-0 left-0 h-screen w-screen z-40">
            <span className="sr-only">Loading...</span>
            <div className="h-5 w-5 bg-primary rounded-full animate-bounce [animation-delay:-0.3s]" />
            <div className="h-5 w-5 bg-primary rounded-full animate-bounce [animation-delay:-0.15s]" />
            <div className="h-5 w-5 bg-primary rounded-full animate-bounce" />
        </div>

    )
}

export default LoadingPage